<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr/>
    <b-card-actions
        class="cari-liste-card"
    >
      <b-row class="mt-50 mb-3">
        <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
          <b-col cols="12">
            <h4 class="text-white d-inline-block">Yeni Rezervasyon</h4>
          </b-col>
        </div>
      </b-row>
      <b-row>
        <b-col lg="2" md="6" sm="6" cols="12">
          <b-form-group label="Oda" label-for="BasicInput">
            <v-select
                v-model="oda"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="odaKodu"
                :options="odalar"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col lg="2" md="6" sm="6" cols="12">
          <b-form-group label="Giriş Tarihi" label-for="BasicInput">
            <flat-pickr
                v-model="girisTarih"
                class="form-control"
                placeholder="Giriş Tarihi"
            />
          </b-form-group>
        </b-col>
        <b-col lg="2" md="6" sm="6" cols="12">
          <b-form-group label="Çıkış Tarihi" label-for="BasicInput">
            <flat-pickr
                v-model="cikisTarih"
                class="form-control"
                placeholder="Çıkış Tarihi"
            />
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" sm="6" cols="12">
          <b-form-group label="Konuk Sayısı" label-for="BasicInput">
            <b-form-spinbutton
                id="sb-inline"
                v-model="konukSayisi"
                inline
                min="0"
            />
          </b-form-group>
        </b-col>
        <b-col lg="1" md="3" sm="6" cols="12">
          <b-form-group label="Oda Kapatma" label-for="BasicInput">
            <b-form-checkbox
                v-model="odaKapatma"
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon"/>
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon"/>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col xl="3" lg="12" sm="12" cols="12">
          <b-form-group label="Rezervasyon Notu" label-for="BasicInput">
            <b-form-textarea
                v-model="aciklama"
                id="textarea-default"
                placeholder="Rezervasyon Notu"
                rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr/>
        </b-col>
      </b-row>
      <b-row class="mt-50 mb-3" v-if="pass">
        <div class="card-header-row" :style="{backgroundColor: $store.getters.bgPrimary}">
          <b-col cols="12">
            <h4 class="text-white d-inline-block">Konuklar</h4>
          </b-col>
        </div>
      </b-row>
      <b-row :style="{'z-index':pass == true ? '100':'-100'}" class="position-relative">
        <b-col cols="12">
          <b-form
              ref="form"
              :style="{height: trHeight}"
              class="repeater-form"
              @submit.prevent="repeateAgain"
              v-if="width >= 1355"
          >
            <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
            >
              <b-col xl="2" lg="3" md="4" sm="6" class="max-width-13">
                <b-form-input
                    id="item-name"
                    type="text"
                    autocomplete="off"
                    placeholder="İsim"
                    v-model="item.isim"
                />
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6" class="max-width-13">
                <b-form-input
                    id="item-name"
                    type="text"
                    autocomplete="off"
                    placeholder="Soyisim"
                    v-model="item.soyIsim"
                />
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6" class="max-width-13">
                <b-form-input
                    id="item-name"
                    type="text"
                    autocomplete="off"
                    placeholder="Tc Kimlik"
                    v-model="item.tcKimlik"
                    @input="tcSearch(index,item.tcKimlik)"
                    :state="termIndex != index ? null : findUsers.length > 0 ? true : null"
                    @keyup.enter="guestAtama()"
                />
                <b-form-valid-feedback
                    tooltip
                    class="users-feedback"
                    v-if="termIndex == index"
                >
                  <ul>
                    <li v-for="(user,i) in findUsers" :key="i" @click="guestAtama(user)">
                      {{ user.adi }} {{ user.soyadi }} / {{ user.tckimlik }}
                    </li>
                  </ul>
                </b-form-valid-feedback>
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6" class="max-width-11">
                <b-form-input
                    id="item-name"
                    type="text"
                    autocomplete="off"
                    placeholder="Telefon"
                    v-model="item.telefon"
                />
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6" class="max-width-10">
                <b-form-select
                    v-model="item.cinsiyet.key"
                    :options="genders"
                    value-field="key"
                    text-field="label"
                />
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6" class="max-width-10">
                <b-form-select
                    v-model="item.onRezervasyon.key"
                    :options="rezTur"
                    value-field="key"
                    text-field="label"
                />
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6" class="max-width-12">
                <b-form-select
                    v-model="item.tarife"
                    :options="tarifeler"
                    value-field="tarifeID"
                    text-field="tarifeAdi"
                />
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6" class="max-width-10">
                <b-form-input
                    id="item-name"
                    autocomplete="off"
                    placeholder="Ücret"
                    step="0.0001"
                    v-model="item.fiyat"
                    type="number"
                    v-numericOnly
                />
              </b-col>
              <b-col
                  lg="2"
                  md="3"
                  class="mb-50 max-width-7 justify-content-end d-flex"
              >
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0"
                    @click="removeItem(index)"
                >
                  <feather-icon
                      icon="XIcon"
                  />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
          <b-form
              ref="form"
              :style="{height: trHeight}"
              class="repeater-form"
              @submit.prevent="repeateAgain"
              v-if="width < 1355"
          >
            <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
            >
              <b-col lg="2" md="4" sm="6" cols="12" class="pr-0">
                <b-form-input
                    id="item-name"
                    type="text"
                    autocomplete="off"
                    placeholder="İsim"
                    v-model="item.isim"
                />
              </b-col>
              <b-col lg="2" md="4" sm="6" cols="12" class="pr-0" :class="width < 576 ? 'mt-50':''">
                <b-form-input
                    id="item-name"
                    type="text"
                    autocomplete="off"
                    placeholder="Soyisim"
                    v-model="item.soyIsim"
                />
              </b-col>
              <b-col lg="3" md="4" sm="6" cols="12" class="pr-0" :class="width < 768 ? 'mt-50':''">
                <b-form-input
                    id="item-name"
                    type="text"
                    autocomplete="off"
                    placeholder="Tc Kimlik"
                    v-model="item.tcKimlik"
                    @input="tcSearch(index,item.tcKimlik)"
                    :state="termIndex != index ? null : findUsers.length > 0 ? true : null"
                    @keyup.enter="guestAtama()"
                />
                <b-form-valid-feedback
                    tooltip
                    class="users-feedback"
                    v-if="termIndex == index"
                >
                  <ul>
                    <li v-for="(user,i) in findUsers" :key="i" @click="guestAtama(user)">
                      {{ user.adi }} {{ user.soyadi }} / {{ user.tckimlik }}
                    </li>
                  </ul>
                </b-form-valid-feedback>
              </b-col>
              <b-col lg="3" md="4" sm="6" cols="12" class="pr-0" :class="width < 992 ? 'mt-50':''">
                <b-form-input
                    id="item-name"
                    type="text"
                    autocomplete="off"
                    placeholder="Telefon"
                    v-model="item.telefon"
                />
              </b-col>
              <b-col lg="2" md="4" sm="6" cols="12" class="pr-0" :class="width < 992 ? 'mt-50':''">
                <b-form-select
                    v-model="item.cinsiyet.key"
                    :options="genders"
                    value-field="key"
                    text-field="label"
                />
              </b-col>
              <b-col lg="3" md="4" sm="6" cols="12" class="pr-0" :class="width < 1355 ? 'mt-50':''">
                <b-form-select
                    v-model="item.onRezervasyon.key"
                    :options="rezTur"
                    value-field="key"
                    text-field="label"
                />
              </b-col>
              <b-col lg="4" md="4" sm="6" cols="12" class="pr-0" :class="width < 1355 ? 'mt-50':''">
                <b-form-select
                    v-model="item.tarife"
                    :options="tarifeler"
                    value-field="tarifeID"
                    text-field="tarifeAdi"
                />
              </b-col>
              <b-col lg="2" md="4" sm="6" cols="12" class="pr-0" :class="width < 1355 ? 'mt-50':''">
                <b-form-input
                    id="item-name"
                    autocomplete="off"
                    placeholder="Ücret"
                    step="0.0001"
                    v-model="item.fiyat"
                    type="number"
                    v-numericOnly
                />
              </b-col>
              <b-col lg="2" md="4" sm="6" cols="12" :class="width < 1355 ? 'mt-50':''">
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0"
                    @click="removeItem(index)"
                >
                  <feather-icon
                      icon="XIcon"
                  />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="repeateAgain"
          >
            <feather-icon
                icon="PlusIcon"
                class="mr-25"
            />
            <span>Konuk Ekle</span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-dark"
              @click="reset()"
              class="float-right"
              :class="width < 351 ? 'mt-25':''"
              :style="{float:width<351 ? 'float-left!important':'float-right'}"
          >
            Sıfırla
          </b-button>
          <b-button variant="relief-success" class="rez-create-button mr-50" @click="createRez()"
                    :class="width < 557 ? ['mt-25','float-left']:''"
          >
            Rezervasyonu Oluştur
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BFormSelectOption,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormSpinbutton,
  BContainer,
  BCard,
  BTable,
  BBadge,
  BModal,
  VBModal,
  BFormSelect,
  BAvatar,
  BAvatarGroup,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBreadcrumb,
  BButton,
  BButtonGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTabs,
  BTab,
  BCardBody,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store'
import axios from 'axios'
import flatpickr from 'flatpickr'
import { nextTick } from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  components: {
    BFormTextarea,
    BCardActions,
    BFormSelectOption,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormSpinbutton,
    BContainer,
    BCard,
    BTable,
    BBadge,
    BModal,
    VBModal,
    vSelect,
    BAvatar,
    BFormSelect,
    BAvatarGroup,
    BCardBody,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButtonGroup,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    flatPickr,
    BBreadcrumb,
    BButton,
    BTabs,
    BTab,
    StatisticCardHorizontal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      findUsers: [],
      termIndex: '',
      pass: false,
      oda: this.$route.params.id,
      girisTarih: '',
      cikisTarih: '',
      aciklama: '',
      konukSayisi: 1,
      odaKapatma: false,
      items: [{
        id: 1,
        isim: '',
        soyIsim: '',
        tcKimlik: '',
        telefon: '',
        fiyat: '',
        tarife: [],
        onRezervasyon: {
          label: 'Normal',
          key: 0,
        },
        cinsiyet: {
          label: 'Erkek',
          key: 1,
        },
        prevHeight: 0,
      }],
      genders: [
        {
          label: 'Erkek',
          key: 1,
        },
        {
          label: 'Kadın',
          key: 2,
        },
      ],
      rezTur: [
        {
          label: 'Normal',
          key: 0,
        },
        {
          label: 'Rezervasyon',
          key: 1,
        },
      ],
      nextTodoId: 2,
    }
  },
  computed: {
    odalar() {
      return this.$store.state.rezervasyon.odaListesi
    },
    tarifeler() {
      return Object.values(this.$store.getters.tarife)
    },
  },
  watch: {
    oda: ['controlPost'],
    girisTarih: ['controlPost'],
    cikisTarih: ['controlPost'],
    konukSayisi: ['controlPost'],
    odaKapatma: ['controlPost'],
  },
  mounted() {
    this.initTrHeight()
  },
  methods: {
    createRez() {
      let islem = true
      const schema = this.postSchema()
      schema.set('islem', 'confirm')
      schema.append('aciklama', this.aciklama)
      this.items.forEach((user, i) => {
        if (typeof user.tarife != 'object') {
          schema.append(`konukListesi[${i}][adi]`, user.isim)
          schema.append(`konukListesi[${i}][soyAdi]`, user.soyIsim)
          schema.append(`konukListesi[${i}][tcKimlik]`, user.tcKimlik)
          schema.append(`konukListesi[${i}][telefon]`, user.telefon)
          schema.append(`konukListesi[${i}][fiyat]`, user.fiyat.replace(/,/g, '.') || 0)
          schema.append(`konukListesi[${i}][cinsiyet]`, user.cinsiyet.key)
          schema.append(`konukListesi[${i}][tarife]`, user.tarife)
          schema.append(`konukListesi[${i}][onRezervasyon]`, user.onRezervasyon.key)
        } else {
          islem = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata!',
              text: `Lütfen ${i + 1}. konuğun tarifesini seçiniz.`,
              icon: 'ThumbsDownIcon',
              variant: 'warning',
            },
          })
        }
      })
      if (this.items.length != 0) {
        if (islem) {
          axios.post(store.state.POST_URL, schema, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
              .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                if (res.data.result.status == 200) {
                  this.$router.push(`/rezervasyon/${res.data.result.rezervasyonNo}`)
                  store.commit('setToken', res.data.userToken)
                  const mesaj = this.$store.getters.notificationSettings(res.data)
                  if (mesaj) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    }, { position })
                  }
                } else {
                  const mesaj = this.$store.getters.notificationSettings(res.data)
                  if (mesaj) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Hata',
                        text: mesaj,
                        icon: 'ThumbsDownIcon',
                        variant: 'warning',
                      },
                    })
                  }
                }
              })
              .catch(err => {
                const mesaj = this.$store.getters.notificationSettings(err.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Lütfen Konuk Ekleyiniz',
            icon: 'ThumbsDownIcon',
            variant: 'warning',
          },
        })
      }
    },
    guestAtama(user) {
      const atanacakItem = this.items[this.termIndex]
      if (user) {
        atanacakItem.isim = user.adi
        atanacakItem.soyIsim = user.soyadi
        atanacakItem.tcKimlik = user.tckimlik
        atanacakItem.telefon = user.tel
        atanacakItem.cinsiyet = user.cinsiyet == 1 ? {
          label: 'Erkek',
          key: 1,
        } : {
          label: 'Kadın',
          key: 2,
        }
      } else {
        atanacakItem.isim = this.findUsers[0].adi
        atanacakItem.soyIsim = this.findUsers[0].soyadi
        atanacakItem.tcKimlik = this.findUsers[0].tckimlik
        atanacakItem.telefon = this.findUsers[0].tel
        atanacakItem.cinsiyet = this.findUsers[0].cinsiyet == 1 ? {
          label: 'Erkek',
          key: 1,
        } : {
          label: 'Kadın',
          key: 2,
        }
      }
      this.termIndex = ''
      this.findUsers = []
    },
    tcSearch(index, term) {
      this.termIndex = index
      this.guestSorgu(term)
    },
    guestSorgu(term) {
      const fd = new FormData()
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'otel')
      fd.append('methodName', 'guestSorgu')
      fd.append('term', term)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(res => this.findUsers = res.data)
    },
    controlPost() {
      if (this.girisTarih && this.cikisTarih) {
        axios.post(store.state.POST_URL, this.postSchema(), {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
            .then(res => {
              if (res.data.result.status == 200) {
                this.pass = true
                let addItemCount = this.konukSayisi - this.items.length
                if (addItemCount > 0) {
                  for (let i = 0; i < addItemCount; i++) {
                    this.repeateAgain()
                  }
                }
                if (addItemCount < 0) {
                  while (addItemCount < 0) {
                    this.removeItem()
                    addItemCount++
                  }
                }
                store.commit('setToken', res.data.userToken)
              } else {
                this.pass = false
                const mesaj = this.$store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'warning',
                    },
                  })
                }
              }
            })
            .catch(err => {
              this.pass = false
              const mesaj = this.$store.getters.notificationSettings(err.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      }
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'rezervasyon')
      fd.append('methodName', 'yeniRezervasyon')
      fd.append('islem', 'rezervasyonKontrol')
      fd.append('odaKodu', typeof this.oda !== 'object' ? this.oda : this.oda.odaKodu)
      fd.append('girisTarih', this.girisTarih)
      fd.append('cikisTarih', this.cikisTarih)
      fd.append('konukSayisi', this.konukSayisi)
      fd.append('odaKapatma', this.odaKapatma === false ? 0 : 1)
      return fd
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        isim: '',
        soyIsim: '',
        tcKimlik: '',
        telefon: '',
        fiyat: '',
        tarife: Object.values(this.$store.getters.tarife)[0]?.tarifeID,
        onRezervasyon: {
          label: 'Normal',
          key: 0,
        },
        cinsiyet: {
          label: 'Erkek',
          key: 1,
        },
        prevHeight: 0,
      })
      this.konukSayisi = this.items.length
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index = this.items.length - 1) {
      this.items.splice(index, 1)
      this.konukSayisi = this.items.length
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    reset() {
      this.findUsers = []
      this.termIndex = ''
      this.pass = false
      this.oda = this.$route.params.id
      this.girisTarih = localStorage.getItem('otelWorkDay')
      this.cikisTarih = ''
      this.aciklama = ''
      this.konukSayisi = 1
      this.odaKapatma = false
      this.items = [{
        id: 1,
        isim: '',
        soyIsim: '',
        tcKimlik: '',
        telefon: '',
        fiyat: '',
        tarife: {},
        onRezervasyon: {
          label: 'Normal',
          key: 0,
        },
        cinsiyet: {
          label: 'Erkek',
          key: 1,
        },
        prevHeight: 0,
      }]
      this.nextTodoId = 2
    },
  },
  created() {
    // const today = new Date().toISOString()
    //     .split('T')[0]
    // if (today != localStorage.getItem('otelWorkDay')) {
    //   localStorage.setItem('diffrentDate', localStorage.getItem('otelWorkDay'))
    // } else {
    //   localStorage.removeItem('diffrentDate')
    // }
    this.items[0].tarife = Object.values(this.$store.getters.tarife)[0]?.tarifeID || localStorage.getItem('otelFirstTarifeID')
    window.addEventListener('resize', this.initTrHeight)
    this.girisTarih = localStorage.getItem('otelWorkDay')
    this.$store
        .dispatch('odaListGet')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  beforeDestroy() {
    this.$store.commit('clearYeniRez')
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
<style scoped>
.max-width-7 {
  max-width: 7% !important;
}

.max-width-10 {
  max-width: 10% !important;
}

.max-width-11 {
  max-width: 11% !important;
}

.max-width-12 {
  max-width: 12% !important;
}

.max-width-13 {
  max-width: 13% !important;
}

.users-feedback {
  background-color: #28c76f;
  cursor: pointer;
  width: 100%;
  height: 24px;
  transform: translate(-2px, -7px);
}

.users-feedback ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: -2px;
  position: relative;
}

.rez-create-button {
  float: right;
  background-color: #28c76f;
}

.rez-create-button:hover {
  float: right;
  background-color: #3AA6B9 !important;
}
</style>
